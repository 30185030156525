<template>
    <section>
        <div style="position: relative ;padding-top:10rem;" class=" mt-0 mb-4">
            <button @click="createReview">click</button>
        </div>

        <ul>
            <li v-for="review in reviews" :key="review">
            {{review.author}}
            <button @click="deleteReview(review.id)">X</button>
            </li>
        </ul>
    </section>
</template>

<script>
import { DataStore } from '@aws-amplify/datastore';
import { Reviews } from '../../models';
export default {
    data() {
        return {
            reviews: []
        }
    },
    methods: {
        async createReview() {
            await DataStore.save(
                new Reviews({

      author: "Kevin Timps",
      message: "Väga mugav, kõik vajalik (ja rohkemgi) on olemas. Kahele inimesele täpselt paras, vajadusel mahub ka neljakesi. Jaana ja tema elukaaslane on väga sõbralikud ja abivalmid. Kindlasti soovitan!",
      rating: 5,
      date: "26/07/2022"
                }));
                console.log('created')
                this.getReviews()
        },
        async getReviews() {
            const models = await DataStore.query(Reviews);
            if(models){
                this.reviews = models,
                console.log(models)
                }
        },
        async deleteReview(id){
        const modelToDelete = await DataStore.query(Reviews, id);
        DataStore.delete(modelToDelete);
        this.getReviews()
        }
    },
    mounted() {
        this.getReviews()
    },
}
</script>
<style lang="scss">
    
</style>